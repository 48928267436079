import React, { useEffect } from "react";
import { connect } from 'react-redux';
import 'react-calendar/dist/Calendar.css';
import { translate } from "../../utils/translate";

const Dashboard = (props) => {

    useEffect(()=> {
        // console.log('dashboard', props)
    },[])

    return (
        <>  
            <div className="row">
                {props.auth.auth.data.userRole !== "investor" ? (
                <>
                    {/* <div className="col-xl-3 col-sm-6">
                        <div className="card gradient-1 shadow-black card-bx">
                            <div className="card-body d-flex align-items-center">
                                <div className="me-auto text-white">
                                    <h3 className="text-white">{0}</h3>
                                    <span className="fs-18">{translate(props.lang, "totalCases")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div className="col-xl-3 col-sm-6">
                        <div className="card gradient-2 shadow-black card-bx">
                            <div className="card-body d-flex align-items-center">
                                <div className="me-auto text-white">
                                    <h3 className="text-white">{0}</h3>
                                    <span className="fs-18">{translate(props.lang, "totalAwaitingCase")}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-sm-6">
                        <div className="card gradient-3 shadow-black card-bx">
                            <div className="card-body d-flex align-items-center">
                                <div className="me-auto text-white">
                                    <h3 className="text-white">{0}</h3>
                                    <span className="fs-18">{translate(props.lang, "totalCustomers")}</span>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </>
                ):null}
            </div>
        </>
   );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};
 
export default connect(mapStateToProps)(Dashboard);
