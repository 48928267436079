import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { translate } from '../../../utils/translate';
import * as XLSX from 'xlsx';
import { DateTime } from 'luxon';

const MuiTable = ({ lang, role, list, exportData, options, onTextSearched, onActionTaken }) => {
    let [opts, setOpts] = useState({
        filter: {
            search: options && (options.search === true || options.search === false) ? options.search : true,
        },
        exports: {
            excel: options && options.excel ? options.excel : false,
            pdf: false,
            txt: false,
        },
        showFooter: options && options.showFooter ? options.showFooter : true,
    })
    const [headers, setHeaders] = useState(list && list.headers ? [...list.headers.filter(head => { if (head.restricted.length < 1 || !head.restricted.includes(role)) { return head } })] : [{ field: 'name', headerName: translate(lang, 'name'), restricted: [] },]);
    const [items, setItems] = useState([]);
    // const [dataLoading, setDataLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [debouncedValue, setDebouncedValue] = useState('');

    const exportExcel = () => {
        let exFileName = `${exportData.name}_${DateTime.now().toUnixInteger().toString()}.xlsx`;
        const wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(exportData.excelData && exportData.excelData.length > 0 ? exportData.excelData : []);
        if (exportData.hasCustom) {
            exportData.customRows.map((cr) => {
                XLSX.utils.sheet_add_aoa(ws, [cr.customData], { origin: cr.origin ?? -1 })
                return true;
            })
        }
        XLSX.utils.book_append_sheet(wb, ws, "Report");
        XLSX.writeFile(wb, exFileName)
    }

    const debounceInput = (event) => {
        const newValue = event;
        setSearchText(newValue);
        // Clear the previous timeout if exists
        clearTimeout(debouncedValue);

        // Set a new timeout to update debouncedValue after a delay
        const timeoutId = setTimeout(() => {
            setDebouncedValue(newValue);
            onTextSearched(event);
            // handleSearch(event)
        }, 300); // Adjust the delay (in milliseconds) as needed
        setDebouncedValue(timeoutId);
    };

    useEffect(() => {
        setItems(list.items);
        setOpts({
            filter: {
                search: options && (options.search === true || options.search === false) ? options.search : true,
            },
            exports: {
                excel: options && options.excel ? options.excel : false,
                pdf: false,
                txt: false,
            },
            showFooter: options ? options.showFooter : true,
        })
    }, [list.items, options])

    return <>
        <div className="d-flex flex-column col-12" style={{ minHeight: '10rem' }}>
            <div className="d-flex flex-column flex-md-row col-12 mb-2">
                {/* Filter and exports */}
                <div className="col-12 col-md-9">
                    <div>
                        {
                            opts.exports.excel ? (<button className="btn table-export-button-excel btn-sm" onClick={(e) => exportExcel(e)}>{translate(lang, 'exportExcel')}</button>) :
                                opts.exports.pdf ? (<button className="btn table-export-button-excel btn-sm" onClick={(e) => exportExcel(e)}>{translate(lang, 'exportPDF')}</button>) :
                                    opts.exports.txt ? (<button className="btn table-export-button-excel btn-sm" onClick={(e) => exportExcel(e)}>{translate(lang, 'exportTxt')}</button>) :
                                        (<></>)
                        }
                    </div>
                </div>
                {
                    opts.filter.search ?
                        (<div className="col-12 col-md-3">
                            <input type="text" className="form-control" placeholder={translate(lang, 'searchHere')} onChange={(e) => { debounceInput(e.target.value) }} />
                        </div>) :
                        (<></>)
                }
            </div>
            <div className="col-12" style={{ height: `${items.length > 0 ? 'auto' : '15rem'}` }}>
                {/* Table */}
                {
                    <DataGrid
                        // loading={dataLoading}
                        rows={[...items]}
                        columns={[...headers]}
                        hideFooter={!opts.showFooter}
                        getRowHeight={() => `auto`}
                        getEstimatedRowHeight={() => 200}
                        sx={{
                            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                                py: 1,
                            },
                            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                                py: '10px',
                            },
                            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                                py: '18px',
                            },
                        }}
                    // initialState={{
                    //     pagination: { paginationModel: { pageSize: 10, page: 0 } }
                    // }}
                    />
                }
            </div>
        </div>
    </>
}
export default (MuiTable);