import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { createUser, editUser, getMemberById, getUserById } from "../../../services/ApiService";
import { genderOptions, promptMessage, roleOption } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import Swal from "sweetalert2";


const AdminForm = (props) => {
    const history = useHistory();
    const lang = props.lang;
    const { type, id } = useParams();
    const roleListOptions = roleOption(props.lang);
    const [data, setData] = useState({
        name: "",
        role: "",
        password: "",
        email: "",
        phone: "",
        username: "",
    });
    const [error, setError] = useState({
        name: "",
        role: "",
        password: "",
        email: "",
        phone: "",
        username: "",
    });

    useEffect(() => {
        if (type === 'create') {
            setData({            
                name: "",
                role: "",
                password: "",
                email: "",
                phone: "",
                username: "",
            })
        } else if (type === 'view') {
            if (!id) {
                history.push('/settings/admin');
                return;
            }
            getDetails(id);
        } else if (type === 'edit') {
            if (!id) {
                history.push('/settings/admin');
                return;
            }
            getDetails(id);
        }
    }, [])

    const getDetails = async (id) => {
        const res = await getUserById(id)
        if (res.status === 'success') {
            let temp = data;
            Object.assign(temp, res.data);
            setData({ ...temp })
        } else {
            history.push('/settings/admin');
            return;
        }
    }

    const handleOptionSelected = (e, name) => {
        const { value } = e;
        const list = { ...data };
        list[name] = value;
        setData(list);
    }

    const submitForm = async () => {
        let check = true;
        let error = { name: "", role: "", password: "", email: "", phone: "", username: ""};
        let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        let onlyNumber = /^\d+$/;
        if (!data.name) {
            check = false;
            error.name = translate(lang, "errorRequired")
        }
        if (!data.username) {
            check = false;
            error.username = translate(lang, "errorRequired")
        }
        if (!data.role) {
            check = false;
            error.role = translate(lang, "errorRequired")
        }
        if (!data.password) {
            check = false;
            error.password = translate(lang, "errorRequired")
        }
        else {
            if (data.password.length < 6) {
                error.password = translate(lang, "invalidPasswordLength")
                check = false;
            }
        }
        if (!data.email) {
            check = false;
            error.email = translate(lang, "errorRequired")
        }else {
            if (!reg.test(data.email)) {
                check = false;
                error.email = translate(lang, "invalidEmailFormat")
            }
        }
        if (!data.phone) {
            check = false;
            error.phone = translate(lang, "errorRequired")
        }else {
            if (!onlyNumber.test(data.phone)) {
                check = false;
                error.phone = translate(lang, "invalidPhoneFormat");
            }
        }
        setError({ ...error });
        if (check) {
            let res;
            if (type === 'create') {
                res = await createUser(data);
            } else if (type === 'edit') {
                res = await editUser(id, data);
            }
            if (res.status === 'success') {
                Swal.fire({
                    title: translate(lang, 'success'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'success',
                    timer: 1500,
                }).then(() => history.push('/settings/admin'))
                resetForm();
            } else {
                Swal.fire({
                    title: translate(lang, 'error'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'error',
                })
            }
        } else {
            promptMessage(lang, 'error', translate(lang, "formErrorMessage"));
        }
    }

    const resetForm = async () => {
        setData({
            name: "",
            role: "",
            password: "",
            email: "",
            phone: "",
            username: ""
        })
    }

    return (
        <>
            <div className="d-flex flex-column flex-md-row">
                <div className="col-12 px-2">
                    <div className="card col-12">
                        <div className="card-header">
                            <h3 className="m-0">
                                {translate(lang, (type === "view" ? "adminDetails" : type === "edit" ? "editAdmin" : "addAdmin"))}
                            </h3>
                        </div>
                        <div className="card-body col-12">
                            <div className="col-12 d-flex flex-row flex-wrap">
                                {/* <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label">{translate(lang, 'memberId')}</label>
                                    <input type="text" value={data.memberId} className="form-control" onChange={(e) => { setData({ ...data, memberId: e.target.value }) }} disabled={type === 'create' ? false : true} readOnly={type === 'create' ? false : true} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                </div> */}
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label">{translate(lang, 'fullName')}</label>
                                    <input type="text" value={data.name} onChange={(e) => { setData({ ...data, name: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.name === "" ? <></> : <p className="text-danger">{error.name}</p>}
                                </div>
                                
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label">{translate(lang, 'role')}</label>
                                    <Select
                                        className="form-control"
                                        value={roleListOptions.filter(
                                            (option) => option.value === data.role
                                        )}
                                        isDisabled={type === 'view' ? true : false}
                                        onChange={(e) => handleOptionSelected(e, "role")}
                                        options={roleListOptions}
                                        placeholder={translate(lang, "placeholderChoose")}
                                        styles={{
                                            control: (base, state) => ({
                                                ...base,
                                                height: "100%",
                                                border: 0,
                                                borderColor: "transparent",
                                                boxShadow: "none",
                                                "&:hover": {
                                                    borderColor: "transparent",
                                                    boxShadow: "none",
                                                },
                                                backgroundColor: "transparent",
                                            }),
                                            valueContainer: (base, state) => ({
                                                ...base,
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                borderColor: "transparent",
                                            })
                                        }}
                                    />
                                    {error.role === "" ? <></> : <p className="text-danger">{error.role}</p>}
                                </div>
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label">{translate(lang, 'username')}</label>
                                    <input type="text" value={data.username} onChange={(e) => { setData({ ...data, username: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.username === "" ? <></> : <p className="text-danger">{error.username}</p>}
                                </div>
                                        <div className="form-group py-2 col-12 col-md-6 px-2">
                                            <label className="form-label">{translate(lang, 'password')}</label>
                                            <input type="password" value={data.password} onChange={(e) => { setData({ ...data, password: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                            {error.password === "" ? <></> : <p className="text-danger">{error.password}</p>}
                                        </div>
                             
                              
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label">{translate(lang, 'email')}</label>
                                    <input type="email" value={data.email} onChange={(e) => { setData({ ...data, email: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.email === "" ? <></> : <p className="text-danger">{error.email}</p>}
                                </div>
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label">{translate(lang, 'phone')}</label>
                                    <input type="tel" value={data.phone} onChange={(e) => { setData({ ...data, phone: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.phone === "" ? <></> : <p className="text-danger">{error.phone}</p>}
                                </div>
                               
                            
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row">
                                <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type}`)}</button>
                                <button type="button" className="btn btn-dark btn-sm mx-2" onClick={() => history.push('/settings/admin')}>{translate(lang, "back")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};

export default connect(mapStateToProps)(AdminForm);