// const url = 'http://localhost:3720';
const url = "https://api-crm.ithouz.com";
const version = '1.0';

const api = {
    login: url + '/login/admin',
    users: url + '/users',
    userProfile: url + '/user/profile/image',
    companyLogo: url + '/company/logo',
    images: url + '/images',
}

export  { api, url, version };