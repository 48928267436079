import { DateTime } from "luxon";
import Swal from "sweetalert2";
import { translate } from "./translate";
import { getCMSType } from "../services/ApiService";

export const promptMessage = (lang, type, msg, optional = {}) => {
    let basic = {
        showConfirmButton: false,
        timer: 2500,
    }
    basic = { ...basic, ...optional }
    if (optional.offTimer) {
        delete basic.timer;
    }
    Swal.fire({
        icon: type,
        title: translate(lang, type),
        text: lang === "EN" ? msg.toUpperCase() : msg,
        ...basic,
    });
}

export const genderOptions = (lang) => {
    let options = [
        { id: 1, label: translate(lang, 'male'), value: "male", isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'female'), value: "female", isSelected: false, isHidden: false, },
    ]
    return options.filter(i => i.isHidden === false);
}

export const roleOption = (lang) => {
    let options = [
        { id: 1, label: translate(lang, 'admin'), value: "admin", isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'master'), value: "master", isSelected: false, isHidden: false, },
    ]
    return options.filter(i => i.isHidden === false);
}


export const languageOptions = (lang) => {
    let langs = [
        { id: 1, label: translate(lang, 'chooseOne'), value: 'en', isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'chooseOne'), value: 'cn', isSelected: false, isHidden: false, },
        { id: 3, label: translate(lang, 'chooseOne'), value: 'bm', isSelected: false, isHidden: false, },
    ]
    return langs.filter(l => l.isHidden === false);
}

export const referenceOptions = (lang) => {
    let ref = [
        { id: 1, label: translate(lang, 'url'), value: 'url', isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'image'), value: 'image', isSelected: false, isHidden: true, },
    ]
    return ref.filter(l => l.isHidden === false);
}

export const userRoleOptions = (lang, uRole) => {
    let userRole = uRole;
    let roles = [
        { id: 1, label: translate(lang, 'master'), value: 'master', allowed: ['master'], isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'admin'), value: 'admin', allowed: ['master', 'admin'], isSelected: false, isHidden: false, },
        { id: 3, label: translate(lang, 'agent'), value: 'agent', allowed: ['master', 'admin', 'agent'], isSelected: false, isHidden: false, },
    ]
    return roles.filter(l => l.isHidden === false && l.allowed.includes(userRole));
}

export const ageCalculator = (dob, option = {}) => {
    let age = 0;
    if (!dob || dob === "") { return age };
    if (typeof dob === 'string') {
        age = DateTime.now().diff(DateTime.fromFormat(dob, option.format), 'years').toObject(); // 2023
        return Math.ceil(age.years);
    } else {
        if (Object.prototype.toString.call(dob) === "[object Date]") {
            age = DateTime.now().diff(DateTime.fromFormat(dob, option.format), 'years').toObject(); // 2023
            return Math.ceil(age.years);
        }
    }
}

export const findDuplicatesInArray = (list, target) => {
    let results;
    results = list.reduce((acc, item) => {
        if (typeof acc[item[target]] !== 'undefined') {
            acc[item[target]]++;
            return acc;
        } else {
            acc[item[target]] = 1;
            return acc;
        }
    }, {})
    var tempArr = [];
    for (var x in results) {
        tempArr.push({
            key: x,
            value: results[x]
        });
    }
    return tempArr;
}

export const unsligify = (name, option) => {
    if (option && option.length) {
        for (let x = 0; x < option.length; x++) {
            let opt = {
                old: option[x] && option[x].old ? option[x].old : '_',
                new: option[x] && option[x].new ? option[x].new : ' ',
                param: option[x] && option[x].param ? option[x].param : 'g',
            };
            let exp = new RegExp(opt.old, opt.param)
            name = name.replace(exp, opt.new);
        }
    } else {
        let opt = {
            old: option && option.old ? option.old : '_',
            new: option && option.new ? option.new : ' ',
            param: option && option.param ? option.param : 'g',
        };
        let exp = new RegExp(opt.old, opt.param)
        name = name.replace(exp, opt.new);
    }
    return name;
}

export const checkingValueWithFormula = (formula, values) => {
    let keys = Object.keys(values);
    let converted = formula;
    for (let x = 0; x < keys.length; x++) {
        let value = values[keys[x]];
        var exp = new RegExp(`%${keys[x]}%`, "g");
        converted = converted.replace(exp, value);
    }
    converted = converted.replace('\'\S', '');
    const final = new Function('return ' + converted)();
    return final;
    // console.log(converted);
}

export const convertToTitle = (text) => {
    let result = text.replace(/(_)+/g, ' ')
    return result;
}

export const toLocaleStringFormat = (value) => {
    const formattedValue = (value ?? 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return formattedValue;
}

export const findFileExtension = (file) => {
    const fn = file.split(".");
    const ext = fn[fn.length - 1];
    return ext;
}