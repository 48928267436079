const bm = {
    // common
    error: "Error",
    success: "Success",
    loading: "Loading",
    noResultFound: "No Result Found",
    goToPage: "Go To Page",
    page: "Page",
    show: "Show",
    searchHere: "Search here",
    searchTable: "Search Table",
    exportExcel: "Export Excel",
    exportPDF: "Export PDF",
    exportTxt: "Export TXT",
    index: "No.",
    name: "Name",
    fullName: "Full Name",
    age: "Age",
    identityCard: "Identity Card",
    birthDate: "Birth Date",
    phoneCode: "Phone Code",
    phone: "Phone",
    email: "Email",
    gender: "Gender",
    memberId: "Member ID",
    action: "Action",
    relationship: "Relationship",
    male: "Male",
    female: "Female",
    status: "Status",
    remark: "Remark",
    list: "List",
    type: "Type",
    description: "Description",
    icon: "Icon",
    placeholderEnterHere: "Enter here",
    placeholderChoose: "Choose one option",
    placeholderChooseMultiple: "Choose option",
    invalidEmailFormat: "Invalid Email Format",
    invalidPhoneFormat: "Invalid Phone Format",
    chooseOne: "Choose One",
    chooseMultiple: "Choose Multiple",
    fillIn: "Fill In",
    username: "Username",
    password: "Password",
    confirmPassword: "Confirm Password",
    invalidPasswordLength: "Password must at least 6 characters",

    // form error message
    errorRequired: "This is required",
    internalServerError: "Internal server error, please contact Admin",
    pinNotMatch: "Pin not match",
    noItemFound: "No item found",
    noQuestionFound: "No Question Found.",
    noAnswerOptionFound: "No Answer Option Found.",
    noSameColorAllowed: "Same color is not allowed in ratings, must be unique",
    distanceCannotEmpty: "Distance of course cannot be empty",

    // confirmation box message
    deleteConfirmation: "Delete Confirmation Box",
    deleteMessage: "Are you sure to remove this? Action cannot be undone.",
    deleteLastCourseConfirmation: "Delete Most Latest Course Confirmation Box",
    deleteLastCourseMessage: "Are you sure to remove this? Action cannot be undone.",
    updateStatusConfirmation: "Update this item status",
    updateStatusMessage: "Are you sure to update this status? Action might affect some data view.",


    // Message box return message

    // button
    create: "Create",
    edit: "Edit",
    save: "Save",
    delete: "Delete",
    back: "Back",
    update: "Update",
    start: "Start",
    pause: "Pause",
    stop: "Stop",
    addNew: "Add New",

    // language
    en: "EN",
    cn: "CN",
    bm: "BM",
    english: "English",
    chinese: "Chinese",
    melayu: "Bahasa Melayu",

    //options
    url: "URL",
    image: "Image",

    // project start
    // nav
    dashboard: "Dashboard",
    member: "Member",
    user: "User",
    tournament: "Tournament",
    settings: "Settings",
    profile: "Profile",
    order: "Order",
    transaction: "Transaction",
    adminList: "Admin List",
    project: "Project",
    cms: "CMS information",
    latestUpdates: "Latest Updates",
    qna: "QnA",
    addUser: "Add User",
    editUser: "Edit User",
    userDetails: "User Details",
    addQnA: "Add QnA",
    editQnA: "Edit QnA",
    qnaDetails: "QnA Details",
    addAdmin: "Add Admin",
    categoryList: "Category List",
    projectPriceList: "Project Price List",
    companyList: "Company List",
    addProjectPrice: "Add Project Price",
    projectPrice: "Project Price",
    sectionName: "Section Name",
    sectionDetails: "Section Details",
    editSection: "Edit Section",
    addSection: "Add Section",
    addCategory: "Add Category",
    option: "Option",
    addProjectPrice: "Add Project Price",
    editProjectPrice: "Edit Project Price",
    price: "Price",
    addSection: "Add Section",
    companyName: "Company Name",
    companyKey: "Company Key",
    editCategory: "Edit Category",
    addInformation: "Add Information",
    role: "Role",
    section: "Section",
    master: "Master",
    admin: "Admin",
    agent: "Agent",
    categoryName: "Category Name",
    companyDetails: "Company Details",
    addCompany: "Add Company",
    editCompany: "Edit Company",

    active: "Active",
    inactive: "Inactive",
    title: "Title",
    smallTitle: "Small Title",

    warning: "Warning",
    info: "Info",
    validateForm: "Validating Form...",
    formErrorMessage: "Form input error, please recheck!",
    nothingToShowHere: "Nothing To Show Here",
    nothingToDelete: "Nothing To Delete",

    add: "Add",
    section: "Section",
    category: "Category",

    route: "Route",
}

export default bm;