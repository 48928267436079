import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { creaeteCategory, createUser, editCategory, editUser, getMemberById, getCategoryById, getCompany, getPage, createCategory, createCompany, editCompany, getCompanyById } from "../../../services/ApiService";
import { genderOptions, pageOption, promptMessage, roleOption } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import Swal from "sweetalert2";


const CompanyForm = (props) => {
    const history = useHistory();
    const lang = props.lang;
    const { type, id } = useParams();
    // const pageOptions = pageOption(props.lang);
    const [data, setData] = useState({
        companyName: "",
        companyKey: "",
    });
    const [error, setError] = useState({
        companyName: "",
        companyKey: "",
        // page: "",
        // companyId: "",


    });

    useEffect(() => {
        if (type === 'create') {
            setData({
                companyName: "",
                companyKey: "",
            })
        } else if (type === 'view') {
            if (!id) {
                history.push('/settings/company');
                return;
            }
            getDetails(id);
        } else if (type === 'edit') {
            if (!id) {
                history.push('/settings/company');
                return;
            }
            getDetails(id);
        }
    }, [])

    const getDetails = async (id) => {
        const res = await getCompanyById(id)

        if (res.status === 'success') {
            let temp = data;
            Object.assign(temp, res.data);
            // getPageOption(res.data.companyId)
            setData({ ...temp })
        } else {
            history.push('/settings/company');
            return;
        }
    }

    const submitForm = async () => {
        let check = true;
        let error = { companyName: "", companyKey: "" };
        if (!data.companyName) {
            check = false;
            error.companyName = translate(lang, "errorRequired")
        }
        if (!data.companyKey) {
            check = false;
            error.companyKey = translate(lang, "errorRequired")
        }

        setError({ ...error });
        if (check) {
            let res;
            if (type === 'create') {
                res = await createCompany(data);
            } else if (type === 'edit') {
                res = await editCompany(id, data);
            }
            if (res.status === 'success') {
                Swal.fire({
                    title: translate(lang, 'success'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'success',
                    timer: 1500,
                }).then(() => history.push('/settings/company'))
                resetForm();
            } else {
                Swal.fire({
                    title: translate(lang, 'error'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'error',
                })
            }
        } else {
            promptMessage(lang, 'error', translate(lang, "formErrorMessage"));
        }
    }

    const resetForm = async () => {
        setData({
            companyName: "",
            companyKey: "",
        })
    }

    return (
        <>
            <div className="d-flex flex-column flex-md-row">
                <div className="col-12 px-2">
                    <div className="card col-12">
                        <div className="card-header">
                            <h3 className="m-0">
                                {translate(lang, (type === "view" ? "companyDetails" : type === "edit" ? "editCompany" : "addCompany"))}
                            </h3>
                        </div>
                        <div className="card-body col-12">
                            <div className="col-12 d-flex flex-row flex-wrap">
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label">{translate(lang, 'companyName')}</label>
                                    <input type="text" value={data.companyName} onChange={(e) => { setData({ ...data, companyName: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.companyName === "" ? <></> : <p className="text-danger">{error.companyName}</p>}
                                </div>
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label">{translate(lang, 'companyKey')}</label>
                                    <input type="text" value={data.companyKey} onChange={(e) => { setData({ ...data, companyKey: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.companyKey === "" ? <></> : <p className="text-danger">{error.companyKey}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row">
                                <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type}`)}</button>
                                <button type="button" className="btn btn-dark btn-sm mx-2" onClick={() => history.push('/settings/company')}>{translate(lang, "back")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};

export default connect(mapStateToProps)(CompanyForm);