/// Pages

import Dashboard from "../jsx/pages/Dashboard";
import AdminList from "../jsx/pages/settings/AdminList";
import AdminForm from "../jsx/pages/settings/AdminForm";
import CMSInformation from "../jsx/pages/settings/CMSInformation";
import CompanyForm from "../jsx/pages/settings/CompanyForm";
import CompanyList from "../jsx/pages/settings/CompanyList";
import SectionList from "../jsx/pages/settings/SectionList";
import SectionForm from "../jsx/pages/settings/SectionForm";
import ContactList from "../jsx/pages/contact/ContactList";



const routes =  [
    /// Dashboard
    { url: "", component: Dashboard, regex: 'dashboard', name: "dashboard" },
    { url: "dashboard", component: Dashboard, regex: 'dashboard', name: "dashboard" },

    { url: "contact/list", component: ContactList, regex: 'contact', name: "contact" },
   
    { url: "settings/CMSInformation", component: CMSInformation, regex: 'CMSInformation', name: "cms" },
    { url: "settings/company", component: CompanyList, regex: 'company', name: "company" },
    { url: "settings/company/:type/:id?", component: CompanyForm, regex: 'company', name: "company" },
    { url: "settings/section", component: SectionList, regex: 'section', name: "section" },
    { url: "settings/section/:type/:id?", component: SectionForm, regex: 'section', name: "section" },
    { url: "settings/admin", component: AdminList, regex: 'admin', name: "admin" },
    { url: "settings/admin/:type/:id?", component: AdminForm, regex: 'admin', name: "admin" },
  
];

export default routes;