/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
/// Link
import { Link, useHistory } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { companyName } from "../../../utils/info";
import { translate } from "../../../utils/translate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentDots, faGears } from '@fortawesome/free-solid-svg-icons'

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import plus from "../../../images/plus.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = (props) => {
  const lang = props.lang;
  const role = props.auth.auth.data.role;
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
  const history = useHistory();
  const [path, setPath] = useState("");
  const [pathHeader, setPathHeader] = useState("");
  const boxRef = useRef(null);

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var navBackdrop = document.querySelector(".navigation-backdrop-control");
    var rightContainer = document.querySelector(".header-right-container");
    // var aaa = document.querySelector("#main-wrapper");
    // function toggleFunc() {
    //   return aaa.classList.toggle("menu-toggle");
    // }
    // btn.addEventListener("click", toggleFunc);

    btn.addEventListener("click", () => toggleMenuFunc());
    navBackdrop.addEventListener("click", () => toggleMenuFunc(true));
    rightContainer.addEventListener("click", () => toggleMenuFunc(true));
  }, []);
  const toggleMenuFunc = (forceClose = false) => {
    var aaa = document.querySelector("#main-wrapper");
    var aaabackdrop = document.querySelector(".navigation-backdrop-control");
    // console.log(aaa.classList.contains("menu-toggle"), 'check');
    if (forceClose) {
      aaabackdrop.classList.add('d-none');
      return aaa.classList.remove("menu-toggle");
    }
    if (aaa.classList.contains("menu-toggle")) {
      aaabackdrop.classList.add('d-none');
      return aaa.classList.remove("menu-toggle");
      // return aaa.classList.toggle("menu-toggle");
    } else {
      aaabackdrop.classList.remove('d-none');
      return aaa.classList.add("menu-toggle");
    }
  }
  let scrollPosition = useScrollPosition();
  /// Path
  // path = path.split("/");
  // path = path[path.length - 1];
  const closeMenu = () => {

    // var element = document.getElementById("main-wrapper");
    // element.classList.remove("menu-toggle");
    if (document.querySelector('body').getAttribute("data-sidebar-style") === 'overlay') {
      document.querySelector(".nav-control").click();
    }
  }
  useEffect(() => {
    history.listen((location) => {
      setPathHeader(location.pathname.split('/')[1]);
      // console.log(location.pathname.split('/')[1])
      // console.log(location.pathname, settings, 'path', settings.indexOf(location.pathname));

      setPath(location.pathname);
    });

    if (!path) {
      setPath(history.location.pathname);
    }
    if (!pathHeader) {
      setPathHeader(history.location.pathname.split('/')[1]);
    }
  }, [history]);

  useEffect(() => {

  }, [pathHeader])
  /// Active menu
  let
    member = ["member"],
    // dashboard = ["dashboard",],
    order = ["order"],
    transaction = ["transaction"],
    user = ["user"],
    settings = ["settings"]
    ;
  return (
    <div
      style={{ zIndex: "10" }}
      className={`deznav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? scrollPosition > 120
          ? "fixed"
          : ""
        : ""
        }`}
      ref={boxRef}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          {['admin', 'master'].includes(role) ? <>
            <li className={`${path.indexOf(["contact"]) > -1 ? "mm-active" : ""}`}>
              <Link className="has-arrow d-flex align-items-center" to="#">
                <FontAwesomeIcon icon={faCommentDots} className="nav-icon" />
                <span className="nav-text">{translate(lang, 'contactForm')}</span>
              </Link>
              <ul className={`mm-collapse ${path.indexOf(['contact']) > -1 ? "mm-show" : ""}`} >
                <li><Link className={`${path.match(/(list)+/i) ? "mm-active" : ""}`} to="/contact/list" onClick={() => closeMenu()}>{translate(lang, 'list')}</Link></li>
              </ul>
            </li>
            <li className={`${path.indexOf(settings) > -1 ? "mm-active" : ""}`}>
              <Link className="has-arrow d-flex align-items-center" to="#">
                <FontAwesomeIcon icon={faGears} className="nav-icon" />
                <span className="nav-text">{translate(lang, 'settings')}</span>
              </Link>
              <ul className={`mm-collapse ${path.indexOf(settings) > -1 ? "mm-show" : ""}`} >
                <li><Link className={`${path.match(/(CMSInformation)+/i) ? "mm-active" : ""}`} to="/settings/CMSInformation" onClick={() => closeMenu()}>{translate(lang, 'cms')}</Link></li>
                <li><Link className={`${path.match(/(section)+/i) ? "mm-active" : ""}`} to="/settings/section" onClick={() => closeMenu()}>{translate(lang, 'section')}</Link></li>
                {/* <li><Link className={`${path.match(/(category)+/i) ? "mm-active" : ""}`} to="/settings/category" onClick={()=>closeMenu()}>{translate(lang, 'categoryList')}</Link></li>
                <li><Link className={`${path.match(/(project)+/i) ? "mm-active" : ""}`} to="/settings/project" onClick={()=>closeMenu()}>{translate(lang, 'projectPriceList')}</Link></li> */}
                <li><Link className={`${path.match(/(company)+/i) ? "mm-active" : ""}`} to="/settings/company" onClick={() => closeMenu()}>{translate(lang, 'companyList')}</Link></li>
                <li><Link className={`${path.match(/(admin)+/i) ? "mm-active" : ""}`} to="/settings/admin" onClick={() => closeMenu()}>{translate(lang, 'adminList')}</Link></li>
              </ul>
            </li>
          </> :
            null
          }
        </MM>
        <div className="copyright">
          <p><strong>{companyName}</strong> © 2023 All Rights Reserved</p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  };
};

export default connect(mapStateToProps)(SideBar);
