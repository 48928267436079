import { applyMiddleware, combineReducers, compose, createStore, } from 'redux';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import i18nReducer from './reducers/i18nReducer';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  auth: AuthReducer,
  i18n: i18nReducer,

});

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware));
