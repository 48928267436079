import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { creaeteCategory, createUser, editCategory, editUser, getMemberById, getCategoryById, getProjectList, createProject, editProject, getProjectById, getCompany, getCMSById, createSection } from "../../../services/ApiService";
import { genderOptions, promptMessage, roleOption } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import Swal from "sweetalert2";


const SectionForm = (props) => {
    const history = useHistory();
    const [ companyListOptions,setCompanyListOptions] = useState([])
    const lang = props.lang;
    const { type, id } = useParams();
    const roleListOptions = roleOption(props.lang);
    const [data, setData] = useState({
        section : "",
        // sectionId: "",
        companyId: "",

    });
    const [error, setError] = useState({
        section : "",
        // sectionId: "",
        companyId: "",

    });

    useEffect(() => {
        if (type === 'create') {
            setData({            
                section : "",
                // sectionId: "",
                companyId: "",
            })
        } else if (type === 'view') {
            if (!id) {
                history.push('/settings/section');
                return;
            }
            getDetails(id);
        } else if (type === 'edit') {
            if (!id) {
                history.push('/settings/section');
                return;
            }
            getDetails(id);
        }
        getCompanyOption();

    }, [])


    const getCompanyOption = async() =>{
        try {
            const res = await getCompany();
            if (res.status === 'success') {
              let options = res.data.map((item) => ({
                  id: item.id,
                  label: item.companyName,
                  value: item.id,
                  isSelected: false,
                  isHidden: false,
                }));
                setCompanyListOptions(options);
                
            } else {
              promptMessage(lang, 'error', res.message ?? 'error');
            }
          } catch (error) {
            // Handle any errors that occur during the API request or data processing
            return [];
          }
    }

    const getDetails = async (id) => {
        const res = await getCMSById(id)
        if (res.status === 'success') {
            console.log(res.data[0],"data")
            setData({ section : res.data[0].section, companyId: res.data[0].companyId })
        } else {
            history.push('/settings/section');
            return;
        }
    }

    const handleOptionSelected = (e, name) => {
        const { value } = e;
        const list = { ...data };
        list[name] = value;
        setData(list);
    }

    const submitForm = async () => {
        let check = true;
        let error = {  
            section : "",
            // sectionId: "",
            companyId: "",};
        let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        let onlyNumber = /^\d+$/;
        if (!data.section) {
            check = false;
            error.section = translate(lang, "errorRequired")
        }

        if (!data.companyId) {
            check = false;
            error.companyId = translate(lang, "errorRequired")
        }
      
        setError({ ...error });
        if (check) {
            let res;
            if (type === 'create') {
                res = await createSection(data);
            } else if (type === 'edit') {
                // res = await editSection(id, data);
            }
            if (res.status === 'success') {
                Swal.fire({
                    title: translate(lang, 'success'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'success',
                    timer: 1500,
                }).then(() => history.push('/settings/section'))
                resetForm();
            } else {
                Swal.fire({
                    title: translate(lang, 'error'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'error',
                })
            }
        } else {
            promptMessage(lang, 'error', translate(lang, "formErrorMessage"));
        }
    }

    const resetForm = async () => {
        setData({
            section : "",
            // sectionId: "",
            companyId: "",
           
        })
    }

    

    return (
        <>
            <div className="d-flex flex-column flex-md-row">
                <div className="col-12 col-md-6 px-2">
                    <div className="card col-12">
                        <div className="card-header">
                            <h3 className="m-0">
                                {translate(lang, (type === "view" ? "sectionDetails" : type === "edit" ? "editSection" : "addSection"))}
                            </h3>
                        </div>
                        <div className="card-body col-12">
                            <div className="col-12 d-flex flex-row flex-wrap">
                                {/* <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label">{translate(lang, 'memberId')}</label>
                                    <input type="text" value={data.memberId} className="form-control" onChange={(e) => { setData({ ...data, memberId: e.target.value }) }} disabled={type === 'create' ? false : true} readOnly={type === 'create' ? false : true} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                </div> */}
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label">{translate(lang, 'section')}</label>
                                    <input type="text" value={data.section} onChange={(e) => { setData({ ...data, section: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.section === "" ? <></> : <p className="text-danger">{error.section}</p>}
                                </div>
                                {/* <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label">{translate(lang, 'sectionID')}</label>
                                    <input type="text" value={data.sectionId} onChange={(e) => { setData({ ...data, sectionId: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.name === "" ? <></> : <p className="text-danger">{error.sectionId}</p>}
                                </div> */}
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label">{translate(lang, 'Company Name')}</label>
                                    <Select
                                        className="form-control"
                                        value={companyListOptions.find(
                                            (option) => option.value === data.companyId
                                        )}
                                        onChange={(e) => handleOptionSelected(e, "companyId")}
                                        options={companyListOptions}
                                        placeholder={translate(lang, "placeholderChoose")}
                                        styles={{
                                            control: (base, state) => ({
                                                ...base,
                                                height: "100%",
                                                border: 0,
                                                borderColor: "transparent",
                                                boxShadow: "none",
                                                "&:hover": {
                                                    borderColor: "transparent",
                                                    boxShadow: "none",
                                                },
                                                backgroundColor: "transparent",
                                                // zIndex: 999999, // Set a higher z-index value for the React Select control
                                            }),
                                            valueContainer: (base, state) => ({
                                                ...base,
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                borderColor: "transparent",
                                                // zIndex: 999999, // Set a higher z-index value for the React Select control
                                            })
                                        }}
                                    />
                                    {error.companyId === "" ? <></> : <p className="text-danger">{error.companyId}</p>}
                                </div>
                                
                               
                            
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row">
                                <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type}`)}</button>
                                <button type="button" className="btn btn-dark btn-sm mx-2" onClick={() => history.push('/settings/section')}>{translate(lang, "back")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};

export default connect(mapStateToProps)(SectionForm);