import { basicAxiosInstance } from '../services/AxiosInstance';

export async function getUserList(params) {
    return basicAxiosInstance.get(`/user/get_users`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getCategoryList(params) {
    return basicAxiosInstance.get(`/category/get_categories`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getUserById(id) {
    return basicAxiosInstance.get(`/user/get_user/${id}`
    ).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createUser(body) {
    return basicAxiosInstance.post(`/user/add_user`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function editUser(id, body) {
    return basicAxiosInstance.put(`/user/edit_user/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeUser(id, body) {
    return basicAxiosInstance.put(`/user/remove/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}



export async function createTournamentPlayer(id, body) {
    return basicAxiosInstance.post(`/tournament/add_player/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function editTournamentPlayer(id, player_id, body) {
    return basicAxiosInstance.put(`/tournament/edit_player/${id}/${player_id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function removeTournamentPlayer(tId, id) {
    return basicAxiosInstance.put(`/tournament/remove_player/${tId}/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getMemberList(params) {
    return basicAxiosInstance.get(`/user/get_members`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}


export async function getMemberById(id, params) {
    return basicAxiosInstance.get(`/user/get_member/${id}`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createMember(body) {
    return basicAxiosInstance.post(`/user/add_member`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function editMember(id, body) {
    return basicAxiosInstance.put(`/user/edit_member/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeMember(id, body) {
    return basicAxiosInstance.put(`/user/remove_member/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getCMSType(id) {

    return basicAxiosInstance.get(`/cms/get_cms?companyId=${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}


export async function getCMS(route, company) {
    return basicAxiosInstance.get(`/cms/get_cms/${route}/${company}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}


export async function getOption(params) {
    return basicAxiosInstance.get(`/option/get_option`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}


export async function getCategoryType() {

    return basicAxiosInstance.get(`/category/get_categories`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}


export async function getCompany() {

    return basicAxiosInstance.get(`/company/get_companies`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function getPage(companyId) {
    return basicAxiosInstance.get(`/cms/get_cms_page/${companyId}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function updateCMS(id, body) {
    return basicAxiosInstance.put(`/cms/update_cms/${id}`, body, {
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}


export async function getCMSById(id) {
    return basicAxiosInstance.get(`/cms/get_cms?id=${id}`
    ).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function uploadCMSImage(body) {
    return basicAxiosInstance.put(`/cms/update_cms_image/`, body, {
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function uploadTextEditorImage(id, body) {
    return basicAxiosInstance.put(`/cms/update_cms_image/${id}`, body, {
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeImageById(id, body) {
    return basicAxiosInstance.put(`/cms/remove_cms_image/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}



export async function createCategory(body) {
    // const pageValues = body.page.map(pageItem => pageItem.value);
    return basicAxiosInstance.post(`/category/add_category`, {
        ...body,
        // companyId : body.companyId,
        // page : pageValues
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function editCategory(id, body) {
    // const pageValues = body.page.map(pageItem => pageItem.value);
    return basicAxiosInstance.put(`/category/edit_category/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}
export async function getCategoryById(id) {
    return basicAxiosInstance.get(`/category/get_categories/${id}`
    ).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeCategory(id, body) {
    return basicAxiosInstance.put(`/category/remove/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}


export async function getProjectList(params) {
    return basicAxiosInstance.get(`/project/get_project_cost`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createProject(body) {
    return basicAxiosInstance.post(`/project/add_project_cost`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createSection(body) {
    return basicAxiosInstance.post(`/cms/add_cms`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function editProject(id, body) {
    return basicAxiosInstance.put(`/project/edit_project_cost/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}
export async function getProjectById(id) {
    return basicAxiosInstance.get(`/project/get_project_cost/${id}`
    ).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeProject(id, body) {
    return basicAxiosInstance.put(`/project/remove/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeSection(id, body) {
    return basicAxiosInstance.put(`/cms/remove/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}



export async function createCompany(body) {
    // const pageValues = body.page.map(pageItem => pageItem.value);
    return basicAxiosInstance.post(`/company/add_company`, {
        ...body,
        // companyId : body.companyId,
        // page : pageValues
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function editCompany(id, body) {
    // const pageValues = body.page.map(pageItem => pageItem.value);
    return basicAxiosInstance.put(`/company/edit_company/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}
export async function getCompanyById(id) {
    return basicAxiosInstance.get(`/company/get_company/${id}`
    ).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeCompany(id, body) {
    return basicAxiosInstance.put(`/company/remove/${id}`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getContactUsFormList(params) {
    return basicAxiosInstance.get(`/contact/get_contacts`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getContactUsFormById(id) {
    return basicAxiosInstance.get(`/contact/get_contact/${id}`
    ).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}




