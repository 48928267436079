import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { removeMember,getUserList, removeUser, getCategory, getCategoryList, removeCategory, getProjectList, removeProject, getCMSType, getCMS, removeSection  } from "../../../services/ApiService";
import { promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import MuiTable from "../../components/MuiTable";
import { faMarker, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionList = (props) => {
    const lang = props.lang;
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [list, setList] = useState({
        items: [],
        headers: [
            // { field: 'member_id', headerName: translate(lang, 'memberId'), restricted: [], flex: 1, minWidth: 150,},
            { field: 'section', headerName: translate(lang, 'section'), restricted: [], flex: 1, minWidth: 200,},
            // { field: 'page', headerName: translate(lang, 'page'), restricted: [], flex: 1, minWidth: 200,},
            { field: 'companyName', headerName: translate(lang, 'companyName'), restricted: [], flex: 1, minWidth: 200,},
            // { field: 'field', headerName: translate(lang, 'field'), restricted: [], flex: 1, minWidth: 200,},

            { field: 'actions', type:'actions', headerName: translate(lang, 'action'), restricted: ['agent', 'player'], flex: 1, minWidth: 100, getActions: (params) => [
                <Link to={`/settings/section/edit/${params.id}`} title={translate(lang, "edit")} className=" btn btn-sm btn-warning cursor-pointer"><FontAwesomeIcon icon={faMarker}></FontAwesomeIcon></Link>,
                <button onClick={() => removeItem(params.id)} title={translate(lang, "delete")} className="btn btn-sm btn-danger cursor-pointer"><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></button>
            ]},
        ],
    });
    const [exportData, setExportData] = useState({
        name: "member_list_report",
        excelData: [],
        hasCustom: false,
        customRows: [{
            customData: [],
            origin: -1, // last row or specified location like 'A1'
        }],
    });

    useEffect(() => {
        getList()
    }, [searchText])

    async function getList() {
        let query = {};
        if (searchText !== '') {
            query = {
                section : searchText,
                page: searchText,
                option : searchText,
                isSearch: true,
            }
        }
        let res = await getCMS(query);
        if (res?.status === 'success') {
            setList({
                ...list,
                items: res.data,
            })
            setExportData({
                ...exportData,
                excelData: res.data,
            });
        } else {
        }
    }

    function onTextSearched(value) {
        setSearchText(value);
    }

    function onActionTaken(value) {
        if (value === 'remove') {
            removeItem()
        } else if (value === 'edit') {
            history.push('');
        }
    }

    function removeItem(id) {
        Swal.fire({
            title: translate(props.lang, "deleteConfirmation"),
            text: translate(props.lang, "deleteMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await removeSection(id)
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    getList();
                } else {
                    promptMessage(lang, 'error', res.message ?? "error")
                    getList();
                }
            }
        })
    }

    return (
        <>
            <div className="card shadow">
                <div className="card-header">
                    <div>
                        <Link to="/settings/section/create" className="btn btn-primary btn-sm">{translate(lang, 'addSection')}</Link>
                    </div>
                </div>
                <div className="card-body w-100">
                    <MuiTable list={{...list}} role={props.auth.auth.data.role} lang={props.lang} exportData={exportData} onTextSearched={(e) => onTextSearched(e)} onActionTaken={(e) => onActionTaken(e)}></MuiTable>
                    {/* <DataGrid rows={rows} columns={columns} /> */}
                    {/* <div id="job_data" className="dataTables_wrapper">
                        <CustomTable
                            lang={props.lang}
                            columns={tableColumns}
                            data={data}
                            excelExport={true}
                            fileData={fileData}
                        ></CustomTable>
                    </div> */}
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(SectionList);