import { faMarker, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { getContactUsFormList } from "../../../services/ApiService";
import { promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import MuiTable from "../../components/MuiTable";

const ContactList = (props) => {
    const lang = props.lang;
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [list, setList] = useState({
        items: [],
        headers: [
            {
                field: 'company', headerName: translate(lang, 'companyName'), restricted: [], flex: 1, minWidth: 150, valueGetter: (params) => {
                    return params.row?.company?.companyName && params.row?.company?.companyName !== "" ? params.row?.company?.companyName : "-"
                }
            },
            {
                field: 'name', headerName: translate(lang, 'fullName'), restricted: [], flex: 1, minWidth: 150, valueGetter: (params) => {
                    return params.row?.name && params.row?.name !== "" ? params.row?.name : "-"
                }
            },
            {
                field: 'message', headerName: translate(lang, 'message'), restricted: [], flex: 2, minWidth: 300, valueGetter: (params) => {
                    return params.row?.message && params.row?.message !== "" ? params.row?.message : "-"
                }
            },
            {
                field: 'email', headerName: translate(lang, 'email'), restricted: [], flex: 1, minWidth: 200, valueGetter: (params) => {
                    return params.row?.email && params.row?.email !== "" ? params.row?.email : "-"
                }
            },
            {
                field: 'phone', headerName: translate(lang, 'phone'), restricted: [], flex: 1, minWidth: 150, valueGetter: (params) => {
                    return params.row?.phone && params.row?.phone !== "" ? params.row?.phone : "-"
                }
            },
            {
                field: 'subject', headerName: translate(lang, 'subject'), restricted: [], flex: 1, minWidth: 150, valueGetter: (params) => {
                    return params.row?.subject && params.row?.subject !== "" ? params.row?.subject : "-"
                }
            },
            {
                field: 'budget', headerName: translate(lang, 'budget'), restricted: [], flex: 1, minWidth: 150, valueGetter: (params) => {
                    return params.row?.budget && params.row?.budget !== "" ? params.row?.budget : "-"
                }
            },
            {
                field: 'type', headerName: translate(lang, 'type'), restricted: [], flex: 1, minWidth: 150, valueGetter: (params) => {
                    return params.row?.type && params.row?.type !== "" ? params.row?.type : "-"
                }
            },
            // {
            //     field: 'actions', type: 'actions', headerName: translate(lang, 'action'), restricted: ['agent', 'player'], flex: 1, minWidth: 100, getActions: (params) => [
            //         <Link to={`/settings/section/edit/${params.id}`} title={translate(lang, "edit")} className=" btn btn-sm btn-warning cursor-pointer"><FontAwesomeIcon icon={faMarker}></FontAwesomeIcon></Link>,
            //         <button onClick={() => removeItem(params.id)} title={translate(lang, "delete")} className="btn btn-sm btn-danger cursor-pointer"><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></button>
            //     ]
            // },
        ],
    });
    const [exportData, setExportData] = useState({
        name: "contactus_list_report",
        excelData: [],
        hasCustom: false,
        customRows: [{
            customData: [],
            origin: -1, // last row or specified location like 'A1'
        }],
    });

    useEffect(() => {
        getList()
    }, [searchText])

    async function getList() {
        let query = {};
        if (searchText !== '') {
            query = {
                name: searchText,
                phone: searchText,
                email: searchText,
                subject: searchText,
                message: searchText,
                budget: searchText,
                type: searchText,
                isSearch: true,
            }
        }
        let res = await getContactUsFormList(query);
        if (res?.status === 'success') {
            setList({
                ...list,
                items: res.data.data,
            })
            setExportData({
                ...exportData,
                excelData: res.data.data,
            });
        } else {
        }
    }

    function onTextSearched(value) {
        setSearchText(value);
    }

    function onActionTaken(value) {
        return;
        if (value === 'remove') {
            // removeItem()
        } else if (value === 'edit') {
            history.push('');
        }
    }

    return (
        <>
            <div className="card shadow">
                <div className="card-body w-100">
                    <MuiTable list={{ ...list }} role={props.auth.auth.data.role} lang={props.lang} exportData={exportData} onTextSearched={(e) => onTextSearched(e)} onActionTaken={(e) => onActionTaken(e)}></MuiTable>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(ContactList);