import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
/// Scroll
// import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/avatar/pic1.jpg";
// import avatar from "../../../images/avatar/1.jpg";
import { Dropdown } from "react-bootstrap";
import LogoutPage from './Logout';
import { connect } from "react-redux";
import routes from "../../../utils/route";
import { translate } from "../../../utils/translate";

const Header = (props) => {
	//   var path = window.location.pathname.split("/");
	//   var name = path[path.length - 1].split("-");
	//   var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
	//   var finalName = filterName.includes("app")
	//     ? filterName.filter((f) => f !== "app")
	//     : filterName.includes("ui")
	//     ? filterName.filter((f) => f !== "ui")
	//     : filterName.includes("uc")
	//     ? filterName.filter((f) => f !== "uc")
	//     : filterName.includes("basic")
	//     ? filterName.filter((f) => f !== "basic")
	//     : filterName.includes("jquery")
	//     ? filterName.filter((f) => f !== "jquery")
	//     : filterName.includes("table")
	//     ? filterName.filter((f) => f !== "table")
	//     : filterName.includes("page")
	//     ? filterName.filter((f) => f !== "page")
	//     : filterName.includes("email")
	//     ? filterName.filter((f) => f !== "email")
	//     : filterName.includes("ecom")
	//     ? filterName.filter((f) => f !== "ecom")
	//     : filterName.includes("chart")
	//     ? filterName.filter((f) => f !== "chart")
	//     : filterName.includes("editor")
	//     ? filterName.filter((f) => f !== "editor")
	//     : filterName; 
	const [header, setHeader] = useState("");
	// const [path, setPath] = useState("");
	const history = useHistory();

	useEffect(() => {
		let currentRoute = [];
		let pathname = window.location.pathname.slice(1);
		currentRoute = routes.filter(i => pathname.match(i.regex));
		if (currentRoute.length > 0) {
			setHeader(translate(props.lang, currentRoute[0].name));
			

		}
		
		
		history.listen((location) => {
			let pathname = window.location.pathname.slice(1);
			// setPath(location.pathname);
			currentRoute = routes.filter(i => pathname.match(i.regex));
			if (currentRoute.length > 0) {
				setHeader(translate(props.lang, currentRoute[0].name));
			}
		})
	}, [history, props.lang]);

	useEffect(() => {
		// calling empty useEffect to trigger UI refresh
	}, [header])

	return (
		<div className="header">
			<div className="header-content">
				<nav className="navbar navbar-expand">
					<div className="collapse navbar-collapse justify-content-between header-right-container">
						<div className="header-left">
							<div
								className="dashboard_bar"
								style={{ textTransform: "capitalize" }}
							>
								{header}
							</div>

						</div>
						<ul className="navbar-nav header-right main-notification">
							
							<Dropdown as="li" className="nav-item dropdown header-profile">
								<Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
									<img src={profile} width={20} alt="" />
									<div className="header-info ms-3">
										<span>{props.auth?.auth.data?.fullName}</span>
										<small>{props.auth?.auth.data?.role}</small>
									</div>
								</Dropdown.Toggle>

								<Dropdown.Menu align="right" className="mt-3 dropdown-menu dropdown-menu-end">
									<LogoutPage />
								</Dropdown.Menu>
							</Dropdown>
						</ul>
					</div>
				</nav>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	const { auth, i18n } = state;
	return {
		lang: i18n.lang,
		auth: auth,
	}
}

export default connect(mapStateToProps)(Header);
